import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import RedeemIcon from '@mui/icons-material/Redeem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
export default function NavbarTopDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAuth, setIsAuth] = React.useState(
    window.localStorage.getItem("isAuth") || false
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const logoutHandler = () => {
    setAnchorEl(null);
    setIsAuth(false);
    window.localStorage.clear();
    navigate("/" , {state : {from : 'navbar'}});
    window.location.reload();
  };
  const n = window.localStorage.getItem("name");

  if (isAuth) {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            //   border: "1px solid red",
          }}
        >
          <Tooltip>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>{n?.[0]}</Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          style={{
            position: "absolute",
            top: 20,
          }}
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 1,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",

                top: 80,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* <MenuItem onClick={handleClose}>
            <Avatar /> Profile
          </MenuItem> */}
          {window.localStorage.role !== 'org' && (
            <>
            <NavLink
            to={`/dashboard/${window.localStorage.id}`}
            exact
            style={{ textDecoration: "none", color: "black" }}
            className="nav-link"
          >
            <MenuItem onClick={handleClose}  sx={{display:'flex',justifyContent:'space-between'  , flexDirection: 'row', gap: '10px' , alignItems:'center'}}>
            <AccountCircleIcon fontSize="medium" />
              My dashboard
            </MenuItem>
          </NavLink>
          <Divider />
          <NavLink
            to={`/dashboard/${window.localStorage.id}?url=courses`}
            exact
            style={{ textDecoration: "none", color: "black"}}
            className="nav-link"
          >
          <MenuItem onClick={handleClose} sx={{display:'flex',justifyContent:'space-between'  , flexDirection: 'row', gap: '10px' , alignItems:'center'}}>
              <CollectionsBookmarkIcon fontSize="medium" />
            My Courses
          </MenuItem> 
          </NavLink>
          <Divider />
          <NavLink
            to={`/dashboard/${window.localStorage.id}?url=earnedcertificates`}
            exact
            style={{ textDecoration: "none", color: "black" }}
            className="nav-link"
          >
          <MenuItem onClick={handleClose} sx={{display:'flex',justifyContent:'space-between'  , flexDirection: 'row', gap: '10px' , alignItems:'center'}}>
              <CardMembershipIcon fontSize="medium"  />
            My Certificates
          </MenuItem> 
          </NavLink>
          <Divider />
          <NavLink
            to={`/dashboard/${window.localStorage.id}?url=redeem`}
            exact
            style={{ textDecoration: "none", color: "black" }}
            className="nav-link"
          >
          <MenuItem onClick={handleClose} sx={{display:'flex',justifyContent:'space-between' , flexDirection: 'row', gap: '10px' , alignItems:'center'}}>
              <RedeemIcon fontSize="medium"  />
            My Points
          </MenuItem> 
          </NavLink>
          <Divider />
            </>
          )} 
          
          <MenuItem onClick={logoutHandler}  sx={{display:'flex',justifyContent:'space-between'  , flexDirection: 'row', gap: '10px' , alignItems:'center'}}>
            <ListItemIcon>
              <Logout fontSize="medium" sx={{color:'#000'}}/>
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    );

  }
//  
}
