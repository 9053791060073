import React from "react";
import "./index.css";

const VideoDemo = () => {
  return (
    <div className="video-demo-container">
      <div className="video-wrapper">
        <iframe
          className="responsive-video"
          src="https://www.youtube.com/embed/wKOdkit9dPQ"
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoDemo;
