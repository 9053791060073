const { BASE_URL } = require("./client");


const ORG_REGISTER = BASE_URL + '/api/org/signup';
const ORG_LOGIN = BASE_URL + '/api/org/login';
const ORG_UPLOAD = BASE_URL + '/api/org/upload/';
const ORG_UPLOAD_REPLACE = BASE_URL + '/api/org/upload/replace/';
const ORG_FETCH = BASE_URL + '/api/org/fetch/';
const ORG_USER = BASE_URL + '/api/org/student/';
const ORG_STUDENT_BOOK = BASE_URL + '/api/org/student/booking/';
const ORG_STUDENT_BOOK_RESUME = BASE_URL + '/api/org/bookings/';
const ORG_STUDENT_SINGLE = BASE_URL + '/api/org/bookings/';









export {ORG_REGISTER , ORG_LOGIN , ORG_UPLOAD , ORG_FETCH , ORG_USER , ORG_STUDENT_BOOK , ORG_UPLOAD_REPLACE , ORG_STUDENT_BOOK_RESUME , ORG_STUDENT_SINGLE};