import * as React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NavbarTopDropdown from '../Navbar/NavbarTopDropdown';
import '../../stylesheets/dashboard.css';
import {Divider} from '@mui/material';
import SideDrawer from './SideDrawer/index';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from '../Footer/Footer';
import { useNavigate , useLocation } from 'react-router-dom';


const Dashboard = ({isAuth,setIsAuth}) => {
  const isMobile = useMediaQuery('(max-width:820px)');
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean); // Filter out empty segments
    const idFromStorage = localStorage.getItem("id");
    console.log(pathSegments);
    // Check if the path is `/dashboard` without an ID
    if (pathSegments[0] === "dashboard" && !pathSegments[1]) {
      if (idFromStorage) {
        navigate(`/dashboard/${idFromStorage}`, { replace: true }); // Use replace to avoid adding to history
      } else {
        console.error("No ID found in localStorage!");
        // Optionally redirect or show a 404 page
      }
    }
  }, []);
    return (
        (<div>
            {isAuth&&(
                <>
                <SideDrawer isAuth={isAuth}/>
                </>
            )} 
        </div>)
    );
}

export default Dashboard;