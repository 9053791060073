import React, { useState } from 'react';
import { Grid, Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Paper ,   Input,
  CircularProgress, } from '@mui/material';
import { Home as HomeIcon, Person as ProfileIcon, School as LearningIcon, CalendarToday as CalendarIcon } from '@mui/icons-material';
import DateTimeSelection from '../DateTimeSelection';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { FORGOT_PASSWORD } from "../../../api/forgotPassword";
import { ORG_STUDENT_BOOK_RESUME } from '../../../api/orgAuth';
function formatDate(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  const getOrdinal = (n) => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
  };

  return `${day}${getOrdinal(day)} ${month} ${year}`;
}

const DashboardComponent = ({ projectsCompleted, conceptsLearnt, daysLeft, trainingScore, announcements, editingBooking, formData, setFormData, handleScheduleInterview, open, setOpen, studentData }) => {
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [mockInterviewOpen, setMockInterviewOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetStatus, setResetStatus] = useState('');
  const [selected, setSelected] = useState(0);
  const [viewMode, setViewMode] = useState("interviews"); // New state to toggle between interviews and resumes
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    console.log(event.target.files[0])
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadResume = () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }
    console.log(selectedFile);
    const formData = new FormData();
    formData.append('resume2', selectedFile);
  
    setIsUploading(true);
  
    axios
      .put(
        `${ORG_STUDENT_BOOK_RESUME}${studentData?.orgBookings[0]?._id}/resume`, // Ensure the endpoint is correct
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Correct Content-Type for file uploads
          },
        }
      )
      .then((response) => {
        console.log("Upload response:", response);
        alert("Resume uploaded successfully!");
        setSelectedFile(null);
        setIsUploading(false);
        // Uncomment this line if you need to refresh student data after upload
        // fetchStudentData();
      })
      .catch((error) => {
        console.error("Error uploading resume:", error);
        alert("Failed to upload resume. Please try again.");
        setIsUploading(false);
      });
  };
  
  
  const handleProfileClick = () => setProfileDialogOpen(true);
  const handleProfileClose = () => setProfileDialogOpen(false);

  const handleMockInterviewClick = () => setMockInterviewOpen(true);
  const handleMockInterviewClose = () => setMockInterviewOpen(false);

  const handlePasswordReset = async () => {
    try {
      const response = await axios.post(FORGOT_PASSWORD, { email: resetEmail });
      setResetStatus('Password reset link sent! Check your email.');
    } catch (error) {
      setResetStatus('Error sending reset link. Please try again.');
    }
  };

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" rowGap={1}>
            <Button
              startIcon={<HomeIcon sx={{ width: 40, height: 40, color: selected === 0 ? '#ffff' : '#6a58c6' }} />}
              variant="outlined"
              sx={{ width: '48%', borderRadius: 2, border: 0, boxShadow: 2, height: 120, display: 'flex', flexDirection: 'column', gap: 2, color: selected === 0 ? '#ffff' : '#6a58c6', background: selected === 0 && '#6a58c6' }}
              onClick={() => { setSelected(0); setViewMode("interviews"); }}
            >
              Home
            </Button>
            <Button
              startIcon={<ProfileIcon sx={{ width: 40, height: 40, color: selected === 1 ? '#ffff' : '#6a58c6' }} />}
              variant="outlined"
              sx={{ width: '48%', borderRadius: 2, border: 0, boxShadow: 2, height: 120, display: 'flex', flexDirection: 'column', gap: 2, color: selected === 1 ? '#ffff' : '#6a58c6', background: selected === 1 && '#6a58c6' }}
              onClick={() => {
                setSelected(1);
                handleProfileClick();
              }}
            >
              Profile
            </Button>
            <Button
              startIcon={<LearningIcon sx={{ width: 40, height: 40, color: selected === 2 ? '#ffff' : '#6a58c6' }} />}
              variant="outlined"
              sx={{ width: '48%', borderRadius: 2, border: 0, boxShadow: 2, height: 120, display: 'flex', flexDirection: 'column', gap: 2, color: selected === 2 ? '#ffff' : '#6a58c6', background: selected === 2 && '#6a58c6' }}
              onClick={() => { setSelected(2); setViewMode("resumes"); }}
            >
              Resume
            </Button>
            <Button
              startIcon={<CalendarIcon sx={{ width: 40, height: 40, color: selected === 3 ? '#ffff' : '#6a58c6' }} />}
              variant="outlined"
              sx={{ width: '48%', borderRadius: 2, border: 0, boxShadow: 2, height: 120, display: 'flex', flexDirection: 'column', gap: 2, color: selected === 3 ? '#ffff' : '#6a58c6', background: selected === 3 && '#6a58c6' }}
              onClick={() => { setSelected(3); setOpen(true); }}
            >
              Mock Interview
            </Button>
          </Box>
        </Grid>

        <Grid item xs={9}>
        <Grid container spacing={3}>
      {viewMode === "interviews" ? (
        // Interview Schedule Section
        <Grid item xs={4}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
            <Typography variant="h6">Your Scheduled Interviews</Typography>
            {studentData?.orgBookings?.length > 0 ? (
              studentData.orgBookings.map((booking, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="body1">
                    <strong>Date:</strong> {formatDate(booking.bookingDate)}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Time:</strong> {booking.time}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body1">
                No interviews scheduled yet.
              </Typography>
            )}
          </Paper>
        </Grid>
      ) : (
        // Resume Section
          <Grid item xs={4}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
          <Typography variant="h6">Your Resumes</Typography>
          {studentData?.orgBookings?.length > 0 ? (
            studentData.orgBookings.map((resume, index) => (
              <Box key={index} mb={2}>
                <Typography variant="body1">{resume.resume.replace(/\\/g, "/").split("/").pop()}</Typography>
                <Button
                  variant="contained"
                  sx={{ mt: 1, background: "#6a58c6", width: "100%" }}
                  onClick={() => window.open(resume.resume, "_blank")}
                >
                  Download
                </Button>
              </Box>
            ))
          ) : (
            <Typography variant="body1">No resumes uploaded yet.</Typography>
          )}
          <Box mt={2}>
            <Input
              type="file"
              onChange={handleFileChange}// Accepts only specific file formats
              sx={{ display: "block", mb: 1 }}
            />
            <Button
              variant="outlined"
              fullWidth
              sx={{ color: "#6a58c6", borderColor: "#6a58c6" }}
              onClick={handleUploadResume}
              disabled={isUploading}
            >
              {isUploading ? <CircularProgress size={24} /> : "Upload New Resume"}
            </Button>
          </Box>
        </Paper>
      </Grid>
      )}

      {/* My Interview Score Section */}
      <Grid item xs={4}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
          <Typography variant="h6">My Interview Score</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              mt: "50px",
            }}
          >
            <CircularProgressbar
              value={trainingScore}
              text={`${trainingScore}`}
              strokeWidth={2}
              styles={buildStyles({
                pathColor: `rgba(106, 88, 198, ${trainingScore / 100})`,
                textColor: "#6a58c6",
                trailColor: "#e0e0e0",
              })}
            />
          </Box>
        </Paper>
      </Grid>

      {/* Announcements Section */}
      <Grid item xs={4}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
          <Typography variant="h6">Announcements</Typography>
          {announcements.map((item, index) => (
            <Box
              key={index}
              mb={2}
              p={2}
              border={1}
              borderColor="grey.300"
              borderRadius={2}
            >
              <Typography variant="body1">{item.title}</Typography>
              <Button
                variant="contained"
                sx={{ mt: 1, background: "#6a58c6", width: "100%" }}
              >
                View
              </Button>
            </Box>
          ))}
        </Paper>
      </Grid>
    </Grid>
        </Grid>
      </Grid>

      {/* Profile Dialog */}
      <Dialog open={profileDialogOpen} onClose={handleProfileClose}>
        <DialogTitle>Profile Settings</DialogTitle>
        <DialogContent>
          <TextField
            label="Email for Password Reset"
            fullWidth
            margin="dense"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, mb: 2, background: '#6a58c6' }}
            onClick={handlePasswordReset}
          >
            Send Reset Link
          </Button>
          {resetStatus && <Typography variant="body2" color="textSecondary">{resetStatus}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProfileClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Mock Interview Dialog */}
      <Dialog open={mockInterviewOpen} onClose={handleMockInterviewClose}>
        <DialogTitle>{editingBooking ? 'Edit Interview' : 'Schedule Interview'}</DialogTitle>
        <DialogContent>
          <DateTimeSelection formData={formData} setFormData={setFormData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleScheduleInterview} color="primary">Done</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DashboardComponent;
